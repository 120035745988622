<template>
  <div>
    <b-table-simple
      small
      fixed
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th
            class="text-nowrap"
            style="width: 250px !important;"
          >
            SATIŞ DANIŞMANI
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 160px !important;"
          >
            MARKA
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 160px !important;"
          >
            MODEL
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 160px !important;"
          >
            TARIH
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 180px !important;"
          >
            TEMAS
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 200px !important;"
          >
            MÜŞTERİ ADI
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 200px !important;"
          >
            TELEFON
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 200px !important;"
          >
            E-POSTA
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 200px !important;"
          >
            NOT
          </b-th>
          <b-th
            class="text-nowrap"
            style="width: 120px !important;"
          >
            REKLAM ID
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item,key) in importList">
          <b-tr
            v-if="dataList[key]"
            :key="'row1_' + key"
          >
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][0] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][1] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][2] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][3] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][4] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][5] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][6] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][7] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][8] }}
            </b-td>
            <b-td class="font-small-2 text-primary text-nowrap overflow-hidden">
              {{ dataList[key][9] }}
            </b-td>
          </b-tr>
          <b-tr
            :key="'row2_' + key"
          >
            <b-td
              class="text-nowrap"
              :class="!item.id_com_user? 'bg-primary' : ''"
            >
              <v-select
                v-model="item.id_com_user"
                :options="users"
                label="title"
                :reduce="item => item.id"
                :clearable="false"
                class="select-size-sm"
              >
                <template v-slot:option="option">
                  <div>
                    {{ option.title }}
                  </div>
                  <div>
                    <small class="text-warning">{{ option.brand }}</small>
                  </div>
                </template>
                <template v-slot:selected-option="option">
                  <div class="font-small-2">
                    {{ option.title }}
                  </div>
                </template>
              </v-select>
            </b-td>
            <b-td :class="!item.id_com_brand? 'bg-primary' : ''">
              <v-select
                v-model="item.id_com_brand"
                :options="brands"
                label="title"
                :reduce="item => item.id"
                :clearable="false"
                class="select-size-sm"
              >
                <template v-slot:option="option">
                  <div>
                    {{ option.title }}
                  </div>
                </template>
                <template v-slot:selected-option="option">
                  <div class="font-small-2">
                    {{ option.title }}
                  </div>
                </template>
              </v-select>
            </b-td>
            <b-td :class="!item.id_com_model? 'bg-primary' : ''">
              <v-select
                v-model="item.id_com_model"
                :options="models"
                label="title"
                :reduce="item => item.id"
                :clearable="false"
                class="select-size-sm"
              >
                <template v-slot:option="option">
                  <div>
                    {{ option.title }}
                  </div>
                  <div>
                    <small class="text-warning">{{ option.brand }}</small>
                  </div>
                </template>
                <template v-slot:selected-option="option">
                  <div class="font-small-2">
                    {{ option.title }}
                  </div>
                </template>
              </v-select>
            </b-td>
            <b-td :class="!item.idate? 'bg-primary' : ''">
              <b-form-input
                v-model="item.idate"
                placeholder="Tarih"
                size="sm"
              />
            </b-td>
            <b-td :class="!item.id_com_meet? 'bg-primary' : ''">
              <v-select
                v-model="item.id_com_meet"
                :options="meets"
                label="title"
                :reduce="item => item.id"
                :clearable="false"
                class="select-size-sm"
              >
                <template v-slot:option="option">
                  <div>
                    {{ option.title }}
                  </div>
                </template>
                <template v-slot:selected-option="option">
                  <div class="font-small-2">
                    {{ option.title }}
                  </div>
                </template>
              </v-select>
            </b-td>
            <b-td :class="!item.customer_name? 'bg-primary' : ''">
              <b-form-input
                v-model="item.customer_name"
                placeholder="Müşteri Adı"
                size="sm"
              />
            </b-td>
            <b-td :class="!item.customer_phone? 'bg-primary' : ''">
              <b-form-input
                v-model="item.customer_phone"
                placeholder="Telefon"
                size="sm"
              />
            </b-td>
            <b-td :class="!item.customer_email? 'bg-primary' : ''">
              <b-form-input
                v-model="item.customer_email"
                placeholder="E-Posta"
                size="sm"
              />
            </b-td>
            <b-td
              :class="!item.content? 'bg-primary' : ''"
              class="text-nowrap"
            >
              <b-form-input
                v-model="item.content"
                placeholder="Not"
                size="sm"
              />
            </b-td>
            <b-td class="text-nowrap">
              <b-form-input
                v-model="item.ads_id"
                placeholder="Reklam ID"
                size="sm"
              />
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <b-button
      class="mt-1"
      variant="primary"
      @click="addRow"
    >
      <FeatherIcon icon="PlusIcon" />
      Satır Ekle
    </b-button>
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'SifirSatis',
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    interviewType: {
      type: String,
      default: null,
    },
    interviewSubject: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getUsers']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
    importList() {
      return this.$store.getters['marketingBatchInterviews/importList']
    },
  },
  created() {
    this.getBrandList()
    this.getModelList()
  },
  methods: {
    addRow() {
      this.importList.push({
        id_com_user: null,
        id_com_brand: null,
        id_com_model: null,
        idate: null,
        id_com_meet: null,
        customer_name: null,
        customer_phone: null,
        customer_email: null,
        content: null,
        ads_id: null,
        id_com_interview_type: this.interviewType,
        id_com_interview_subject: this.interviewSubject,
      })
    },
    getBrandList() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getModelList() {
      this.$store.dispatch('models/modelsList', {
        select: [
          'com_model.id AS id',
          'com_model.title AS title',
          'com_brand.name AS brand',
        ],
        where: {
          'com_model.id_com_brand !=': null,
        },
        order_by: ['com_model.id_com_brand', 'ASC'],
      })
    },
  },
}
</script>
