<template>
  <div>
    <b-table
      responsive="sm"
      :items="users"
      :fields="fields"
      striped
      bordered
    />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  name: 'UserList',
  components: {
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'title',
          label: 'Adı Soyadı',
        },
        {
          key: 'brand',
          label: 'Marka',
        },
        {
          key: 'department',
          label: 'Departman',
        },
      ],
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
}
</script>
